<template>
<b-btn v-if="hasActiveBot" :class="classes" class="btn btn-sm btn-light-dark" @click="onShowQrCode(clientUuid)">
  <span class="svg-icon mr-0 btn-light-primary" :title="$t('FORM.SHOW_QR_CODE')">
    <inline-svg src="/media/svg/icons/Shopping/Barcode.svg" />
  </span>
</b-btn>
</template>

<script>
import QRCode from 'qrcode'
import ModalQrCode from '@/components/modals/ModalQrCode'
import Repo from '@/core/repository/company/clientsRepository'

export default {
  name: 'ShowQrCodeButton',
  props: {
    hasActiveBot: {
      type: Boolean,
    },
    clientUuid: {
      type: String,
    },
    classes: {
      type: String,
    },
  },
  methods: {
    onShowQrCode(clientUuid) {
      this.loadingOn()
      Repo.getTelegramBotLink(clientUuid)
        .then((response) => {
          QRCode.toDataURL(response.data.payload, (err, dataUrl) => {
            if (err !== null) {
              this.$toastDanger('TOAST.ERROR')()
            } else {
              this.$modal.show(ModalQrCode, {
                handlers: {
                  load: (ref) => {
                    ref.setAttribute('src', dataUrl)
                  },
                  copy: () => {
                    navigator.clipboard.writeText(response.data.payload)
                      .then(this.$toastSuccess('CLIPBOARD.COPY_SUCCESS'), this.$toastDanger('TOAST.ERROR'))
                  },
                },
              }, {
                height: 'auto',
                adaptive: true,
                clickToClose: false,
              })
            }
          })
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.$toastDanger(this.$t('BOT.ACTIVE_BOT_MISSING_ERR'))()
          } else {
            this.$toastDanger('TOAST.ERROR')()
          }
        })
        .finally(this.loadingOff)
    },
  },
}
</script>

<style scoped>

</style>
