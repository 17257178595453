<template>
<ModalBase :body-class="'text-center'">
  <template #header>
    <h5 class="modal-title" id="exampleModalLabel">{{ $t('MODAL.QR_CODE.TITLE') }}</h5>
    <div class="btn btn-icon" @click="$emit('close')">
      <span class="svg-icon svg-icon-secondary svg-icon-lg">
        <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
      </span>
    </div>
  </template>
  <template #body>
    <img @click="handlers.copy" src="" alt="qr-code" class="d-block m-auto" ref="qrcode" width="50%">
    
    <div class="btn btn-primary" @click="handlers.copy">{{ $t('MODAL.QR_CODE.COPY_LINK') }}</div>
  </template>
  <template #footer>
    <span class="text-muted">{{ $t('MODAL.QR_CODE.FOOTER') }}</span>
  </template>
</ModalBase>
</template>

<script>
import ModalBase from './ModalBase'

export default {
  name: 'ModalQrCode',
  components: { ModalBase },
  props: {
    handlers: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.handlers.load(this.$refs.qrcode)
  },
  methods: {
    load() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
